"use client";
import React, { FC } from 'react';
import styles from './IntroTextHero.module.scss';
import {TextGenerateEffect} from "../ui/text-generate-effect";

interface IntroTextHeroProps {}

const words = 'Cyril Ferté Developer Fullstack Angular / Rust / Typescript / Python / NodeJS / React';

const IntroTextHero: FC<IntroTextHeroProps> = () => (
  <div className="md:px-32 px-10 z-10">
       <TextGenerateEffect words={words} />
      <div className="flex flex-row  flex-wrap space-x-4 mt-12 ">
          <button
              onClick={() => window.open('https://www.malt.fr/profile/cyrilferte1', '_blank')}
              className="inline-flex h-12 animate-shimmer items-center justify-center rounded-md border border-slate-800 bg-[linear-gradient(110deg,#000103,45%,#1e2631,55%,#000103)] bg-[length:200%_100%] px-6 font-medium text-slate-400 transition-colors focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50">
              Malt
          </button>
          <button className="p-[3px] relative">
              <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-lg"/>
              <div
                    onClick={() => window.open('https://www.linkedin.com/in/cferte/', '_blank')}
                  className="px-8 py-2  bg-black rounded-[6px]  relative group transition duration-200 text-white hover:bg-transparent">
                  linkedin
              </div>
          </button>

      </div>
  </div>
);

export default IntroTextHero;
