import React from 'react';
import logo from './logo.svg';
import './App.css';
import IntroTextHero from "./components/IntroTextHero/IntroTextHero";
import {TextGenerateEffect} from "./components/ui/text-generate-effect";

const words = 'nik il me faut de text mais je n\'ai pas le temps';
    function App() {
  return (
          <div
              className=" max-w-full h-screen dark:bg-black bg-white  dark:bg-grid-white/[0.2] bg-grid-black/[0.2] relative flex items-center justify-center   ">
              {/* Radial gradient for the container to give a faded look */}
              <div
                  className="absolute pointer-events-none inset-0 flex items-center justify-center dark:bg-black bg-white [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]"></div>
                  <IntroTextHero/>
          </div>


  );
    }

export default App;
